import { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import css from "./ModalEditarFormacion.module.css"

const ModalEditarFormacion = ({ open, formacion, onClose, onSubmit }) => {

  const [formacionActualizada, setFormacionActualizada] = useState({
    id: "",
    nombre: "",
    email: "",
    centro: "",
    completada: false,
  })

  useEffect(() => {
    if (!formacion) return
    setFormacionActualizada({
      id: formacion.id || "",
      nombre: formacion.nombre || "",
      email: formacion.email || "",
      centro: formacion.centro || "",
      completada: formacion.formacionCompletada || false,
    })
  }, [formacion])

  const handleChange = (field, value)=> {
    setFormacionActualizada({
      ...formacionActualizada,
      [field]: value,
    })
  }

  const handleSubmit = () => {
    onSubmit({
      id: formacionActualizada.id,
      nombre: formacionActualizada.nombre,
      email: formacionActualizada.email,
      centro: formacionActualizada.centro,
      completada: formacionActualizada.completada,
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Editar formación</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Vas a editar una formación en ágora.
        </DialogContentText>
        <TextField
          className={css.input}
          fullWidth
          name="nombre"
          label="Nombre"
          variant="standard"
          value={formacionActualizada.nombre}
          onChange={e => handleChange("nombre", e.target.value)}
        />
        <TextField
          className={css.input}
          fullWidth
          name="email"
          label="Email"
          variant="standard"
          value={formacionActualizada.email}
          onChange={e => handleChange("email", e.target.value)}
        />
        <AcademiaSelector
          className={css.input}
          fullWidth
          name="centro"
          label="Centro"
          variant="standard"
          value={formacionActualizada.centro}
          onChange={e => handleChange("centro", e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formacionActualizada.completada}
              onChange={e => handleChange("completada", e.target.checked)}
            />
          }
          label="Formación completada"
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Editar formación
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarFormacion
