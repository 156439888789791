import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IconButton } from '@mui/material'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import MesRecienteSelector from '../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../utils/auth'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { monthToString } from '../../../utils/date'
import { textFilter } from '../../../utils/table'
import { getFromAsignatura } from '../../../utils/api/pagoTarifas'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import css from './Asignaturas.module.css'

const Asignaturas = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState('')

  const { isLoading: isLoadingTarifas, data: tarifaList=[] } = useQuery({
    queryKey: ['pago-tarifa', 'list', 'asignaturas', mes, centro], 
    queryFn: ()=> getFromAsignatura({ mes, centro })
      .then(datos=> Object.keys(datos).map(asignatura=> ({ asignatura, ingresos: datos[asignatura] })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asignaturas', content: err })
        return []
      })
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Asignatura', 'Ingresos'],
      data: tarifaList,
      transform: c=> ([
        c.asignatura,
        `${c.ingresos.toFixed(2)} €`
      ])
    })
    downloadCSVFile(content, 'asignaturas-tarifas')
  }

  const filterSearch = asignatura=> {
    return textFilter({ 
      object: asignatura, 
      fields: ['asignatura'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoadingTarifas}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
          </div>
        )}
        title='Resultados'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Asignatura', key: 'asignatura', align: 'left' },
          { title: 'Ingresos', key: 'ingresos', align: 'right' },
        ]}
        data={tarifaList
          .filter(filterSearch)
          .map(asignatura=> ({
            asignatura: asignatura.asignatura,
            ingresos: `${asignatura.ingresos.toFixed(2)} €`,
          }))
        }
      />
    </PageLoading>
  )

}

export default Asignaturas