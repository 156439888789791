import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import InformationTable from "../../../components/Table/InformationTable"
import EjercicioSelector from "../../../components/form/EjercicioSelector/EjercicioSelector"
import TableHeader from "../../../components/TableHeader/TableHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import MesSelector from "../../../components/form/MesSelector/MesSelector"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { textFilter } from "../../../utils/table"
import { getValoracionesAlumnos } from "../../../utils/api/alumnos"
import { dateToString } from "../../../utils/date"
import css from "./Valoraciones.module.css"

const Valoracion = ({ valoracion }) => {
  const value = ["MUY_NEGATIVA", "NEGATIVA", "NEUTRAL", "POSITIVA", "MUY_POSITIVA"].indexOf(valoracion) + 1
  if (value) return (
    <>
      <span className={css.valoracion}>{value}</span>
      <span className={css.valoracionMax}>/5</span>
    </>
  )
  else return (
    <span className={css.placeholder}>Sin valoracion</span>
  )
}

const Valoraciones = () => {
  const notification = useNotification()

  const [search, setSearch] = useState("")
  const [mes, setMes] = useState(new Date().getMonth() + 1)
  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())

  const { isLoading, data: valoracionesList = [] } = useQuery({
    queryKey: ["valoracion-alumnos", "list", mes, ejercicio],
    enabled: !!mes && !!ejercicio,
    queryFn: () => getValoracionesAlumnos({ mes: `${mes}/${ejercicio}` })
      .then((datos) => datos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
      .catch((err) => {
        notification.error({ title: "Error al recuperar las valoraciones", content: err })
        return []
      }),
  })
 
  const filterSearch = (valoracion) => {
    return textFilter({
      object: valoracion,
      fields: ["alumnoNombre", "asignatura", "nivel", "profesor"],
      search: search,
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <MesSelector
              className={css.month}
              name="mes"
              label="Mes"
              value={mes}
              onChange={(e) => setMes(e.target.value)}
            />
            <EjercicioSelector
              className={css.year}
              name="ejercicio"
              label="Año"
              minYear={2025}
              maxYear={2030}
              value={ejercicio}
              onChange={(e) => setEjercicio(e.target.value)}
            />
          </div>
        }
        title="Valoraciones"
        search={search}
        onSearchChange={(e) => setSearch(e.target.value)}
      />

      <InformationTable
        details={[
          { title: "Alumno", key: "alumno" },
          { title: "Asignatura", key: "asignatura" },
          { title: "Profesor", key: "profesor" },
          { title: "Centro", key: "centro" },
          { title: "Fecha", key: "fecha" },
          { title: "Valoración clase", key: "valoracionAlumno" },
          { title: "Valoración profesor", key: "valoracionProfesor" },
          { title: "Observaciones", key: "observaciones", cell: css.observaciones },
        ]}
        data={valoracionesList.filter(filterSearch).map((valoracion) => ({
          alumno: valoracion.alumnoNombre,
          asignatura: `${valoracion.asignatura} (${valoracion.nivel})`,
          profesor: valoracion.profesor,
          centro: valoracion.centro,
          fecha: `${dateToString(valoracion.fechaAsistencia)} - ${valoracion.horaAsistencia}`,
          valoracionAlumno: <Valoracion valoracion={valoracion.valoracionClase} />,
          valoracionProfesor: <Valoracion valoracion={valoracion.valoracionProfesor} />,
          observaciones: valoracion.observaciones || "--",
        }))}
      />
    </PageLoading>
  )
}

export default Valoraciones
