import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Fab, IconButton, Link } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import TableHeader from "../../../components/TableHeader/TableHeader"
import InformationTable from "../../../components/Table/InformationTable"
import EjercicioSelector from "../../../components/form/EjercicioSelector/EjercicioSelector"
import { textFilter } from "../../../utils/table"
import { createFormaciones, getFormaciones, updateFormaciones } from "../../../utils/api/formacion"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import ModalCrearFormacion from "../../../modals/ModalCrearFormacion/ModalCrearFormacion"
import ModalEditarFormacion from "../../../modals/ModalEditarFormacion/ModalEditarFormacion"
import css from "./Formaciones.module.css"

const Formaciones = () => {
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState("")
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [formacionSeleccionada, setFormacionSeleccionada]= useState(null)

  const [year, setYear] = useState(new Date().getFullYear())

  const { isLoading, data: formacionList = [] } = useQuery({
    queryKey: ["formacion", "list", year],
    enabled: !!year,
    queryFn: () => getFormaciones(year)
      .then((datos) => datos)
      .catch((err) => {
        notification.error({ title: "Error al recuperar las formaciones", content: err })
        return []
      })
  })
  const { isFetching: isCreatingFormacion, mutate: crearFormacion } =
    useMutation({
      mutationFn: createFormaciones,
      onSuccess: () => {
        setCreateModalOpen(false)
        notification.success({ title: "Formación creada", content: "La formación ha sido creada correctamente" })
        queryClient.invalidateQueries(["formacion", "list"])
      },
      onError: (err) => notification.error({ title: "Error al crear la formación", content: err }),
    })

  const { isFetching: isUpdatingFormacion, mutate: actualizarFormacion } = useMutation({
    mutationFn: updateFormaciones,
    onSuccess: () => {
      notification.success({ title: "Formación actualizadoa", content: "Formación actualizada correctamente" })
      queryClient.invalidateQueries(["formacion", "list"])
      setEditModalOpen(false)
    },
    onError: (err) => notification.error({ title: "Error al actualizar la formación", content: err.message, }),
  })


  const handleCrearFormacion = (formacion) => {
    if (isCreatingFormacion) return
    crearFormacion(formacion)
  }

  const handleEditarFormacion =(formacion)=>{
    if (isUpdatingFormacion) return
    actualizarFormacion(formacion)

  }

  const filterSearch = (formacion) => {
    return textFilter({
      object: formacion,
      fields: ["nombre", "email"],
      search: search,
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <EjercicioSelector
            className={css.year}
            name="year"
            label="Año"
            value={year}
            minYear={2024}
            onChange={e => setYear(e.target.value)}
          />
        }
        title="Formaciones activas"
        search={search}
        onSearchChange={e => setSearch(e.target.value)}
      />

      <InformationTable
        details={[
          { title: "Nombre", key: "nombre" },
          { title: "E-mail", key: "email" },
          { title: "Centro", key: "centro" },
          { title: 'Opciones', key: 'opciones'},
        ]}
        data={formacionList.filter(filterSearch).map((formacion) => ({
          nombre: (
            <Link
              className={css.link}
              href={`https://agora.mundoestudiante.cloud/formaciones/${formacion.id}/ficha`}
              target="_blank"
            >
              {formacion.nombre}
            </Link>
          ),
          email: formacion.email || "--",
          centro: formacion.centro || "--",
          opciones: (
            <IconButton size='small' color='secondary' onClick={() => {
              setFormacionSeleccionada(formacion)
              setEditModalOpen(true)
            }} >
            <i className='material-icons'>edit</i>
          </IconButton>
          )
        }))}
      />
      <Fab
        className={css.fab}
        disabled={isCreateModalOpen}
        onClick={() => setCreateModalOpen(true)}
        size="medium"
        color="primary"
        aria-label="add"
      >
        <i className="material-icons">add</i>
      </Fab>
      <ModalCrearFormacion
        open={isCreateModalOpen}
        onSubmit={handleCrearFormacion}
        onClose={() => setCreateModalOpen(false)}
      />
      <ModalEditarFormacion
       open={isEditModalOpen}
       formacion={formacionSeleccionada}
       onClose={() => setEditModalOpen(false)} 
       onSubmit={handleEditarFormacion}
      />
    </PageLoading>
  )
}

export default Formaciones
